export enum SmartTableFilterControlTypesEnum {
  BOOL_RADIO = 'boolRadio',
  CURRENCY_INPUT = 'currencyInput',
  CURRENCY_RANGE = 'currencyRange',
  DATE_RANGE = 'dateRange',
  DATE_INPUT = 'dateInput',
  DATE_TIME_INPUT = 'dateTimeInput',
  DATE_TIME_RANGE = 'dateTimeRange',
  NUMBER_INPUT = 'numberInput',
  NUMBER_RANGE = 'numberRange',
  TEXT_INPUT = 'textInput',
  SELECT = 'select',
}

type Map = {
  [key: string]: string;
};

export interface SmartTableFilterProps {
  attribute: string;
  controlType: SmartTableFilterControlTypesEnum;
  className?: string;
  dica?: string | JSX.Element;
  label: string;
  minLength?: number;
  maxLength?: number;
  exactLength?: number;
  map?: Map | string[];
}

export interface SmartTableFilterComponentProps {
  filter: SmartTableFilterProps;
  upsertAttributeInSearchParams: (attribute: string, value: string) => void;
  removeAttributeFromSearchParams: (attribute: string) => void;
}
