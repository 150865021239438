import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import BSCollapse from 'react-bootstrap/Collapse';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';

import IconButton from '../../button/icon-button';
import RangeDatepickerFilter from './range-datepicker-filter';
import RangeCurrencyFilter from './range-currency-filter';
import RangeInputFilter from './range-input-filter';
import SearchDatepickerFilter from './search-datepicker-filter';
import SearchInputFilter from './search-input-filter';
import SearchRadioFilter from './search-radio-filter';
import SearchSelectFilter from './search-select-filter';
import {
  SmartTableFilterComponentProps,
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from './table-filter.types';
import { ClassTypesEnum, SizesEnum } from '../../../helpers/enums';

const {
  BOOL_RADIO,
  CURRENCY_RANGE,
  CURRENCY_INPUT,
  DATE_INPUT,
  DATE_RANGE,
  DATE_TIME_RANGE,
  DATE_TIME_INPUT,
  NUMBER_INPUT,
  NUMBER_RANGE,
  SELECT,
  TEXT_INPUT,
} = SmartTableFilterControlTypesEnum;

interface SmartTableFiltersProps {
  filters: SmartTableFilterProps[];
}

export const validateMinLength = (minLength: number, value: string): boolean => {
  return value.trim().length <= minLength;
};

export const getMinLengthErrorMessage = (minLength: number) => `Digite pelo menos ${minLength + 1} caracteres.`;

const SmartTableFilters: React.FC<SmartTableFiltersProps> = ({ filters }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const onClickHandler = () => setSearchParams('');

  const upsertAttributeInSearchParams = (attribute: string, value: string): void => {
    searchParams.set(attribute, value.toString());
    searchParams.sort();

    return setSearchParams(searchParams);
  };

  const removeAttributeFromSearchParams = (attribute: string): void => {
    searchParams.delete(attribute);
    searchParams.sort();

    return setSearchParams(searchParams);
  };

  const handleResize = () => {
    const displayLg = 991;

    if (window.innerWidth <= displayLg) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
      setShowFilters(true);
    }
  };

  const onFilterClickHandler = () => {
    if (isMobile) {
      setShowFilters(!showFilters);
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <Card data-testid="smart-table-filters">
      <Card.Body>
        <div
          className="d-flex align-items-center justify-content-between"
          role={isMobile ? 'button' : ''}
          onClick={onFilterClickHandler}
        >
          <Card.Title>Filtros</Card.Title>

          <div className="d-lg-none">
            <IconButton
              Icon={showFilters ? BsChevronDown : BsChevronUp}
              type={ClassTypesEnum.PRIMARY}
              size={SizesEnum.LARGE}
              onClick={onFilterClickHandler}
              aria-expanded={showFilters}
            />
          </div>
        </div>

        <BSCollapse in={showFilters}>
          <div className="mt-4">
            {filters.map((filter, index) => (
              <Filter
                key={index}
                filter={filter}
                removeAttributeFromSearchParams={removeAttributeFromSearchParams}
                upsertAttributeInSearchParams={upsertAttributeInSearchParams}
              />
            ))}

            <div className="d-flex justify-content-center">
              <Button className="w-100 " variant="primary" onClick={onClickHandler}>
                Limpar
              </Button>
            </div>
          </div>
        </BSCollapse>
      </Card.Body>
    </Card>
  );
};

const Filter: React.FC<SmartTableFilterComponentProps> = ({
  filter,
  removeAttributeFromSearchParams,
  upsertAttributeInSearchParams,
}) => {
  const { controlType } = filter;

  const filterComponentProps = {
    filter,
    upsertAttributeInSearchParams,
    removeAttributeFromSearchParams,
  };

  switch (controlType) {
    case CURRENCY_RANGE:
      return <RangeCurrencyFilter {...filterComponentProps} />;
    case DATE_RANGE:
    case DATE_TIME_RANGE:
      return <RangeDatepickerFilter {...filterComponentProps} />;
    case NUMBER_RANGE:
      return <RangeInputFilter {...filter} {...filterComponentProps} />;
    case BOOL_RADIO:
      return <SearchRadioFilter {...filterComponentProps} />;
    case DATE_INPUT:
    case DATE_TIME_INPUT:
      return <SearchDatepickerFilter {...filterComponentProps} />;
    case CURRENCY_INPUT:
    case NUMBER_INPUT:
    case TEXT_INPUT:
      return <SearchInputFilter {...filterComponentProps} />;
    case SELECT:
      return <SearchSelectFilter {...filterComponentProps} />;
    default:
      return <></>;
  }
};

export default SmartTableFilters;
