import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { BsTrash } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import IconButton from '../../components/button/icon-button';
import ConfirmationDialog from '../../components/dialog/confirmation-dialog';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import {
  ApiMultiElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  mapearERemoverElementosNulosERepetidos,
} from '../../helpers';
import { DialogProps } from '../../hooks/dialog/dialog-context';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadProdutos, selectObjectTodosProdutos } from '../produtos/produtos.redux';
import CadastrarClientePreAprovadoProdutoDialog from './cadastrar-cliente-pre-aprovado-produto-dialog';
import {
  deleteClientePreAprovadoProduto,
  loadClientesPreAprovadosProduto,
  selectClientePreAprovadoProdutoByFilters,
  selectClientePreAprovadoProdutoLoadingStateByFilters,
  selectTotalClientePreAprovadoProdutoByFilters,
} from './cliente-pre-aprovado.redux';

const smartFilters = ({ produtosMap }: { produtosMap: any }): SmartTableFilterProps[] => [
  {
    attribute: 'cpfCnpj',
    label: 'CPF/CNPJ',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
    minLength: 10,
  },
  {
    attribute: 'limiteCredito',
    label: 'Limite de crédito (R$)',
    controlType: SmartTableFilterControlTypesEnum.CURRENCY_RANGE,
  },
  {
    attribute: 'produtoId',
    label: 'Produto',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: produtosMap,
  },
];

const smartColumns = ({
  closeDialog,
  produtos,
  onRemoveClientePreAprovadoProdutoHandler,
  showDialog,
}: {
  closeDialog: () => void;
  produtos: any;
  onRemoveClientePreAprovadoProdutoHandler: (itemDoacaoId: string) => void;
  showDialog: (dialogProps: DialogProps) => void;
}): SmartTableColumnProps[] => [
  {
    label: 'CPF / CNPJ',
    attribute: 'cpfCnpj',
    format: FormatValueEnum.CPF_CNPJ,
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Produto',
    attribute: 'produtoId',
    format(produtoId) {
      if (!produtoId) {
        return '-';
      }

      return (
        <Link onClick={(e) => e.stopPropagation()} to={`/produtos/${produtoId}`}>
          {produtos[produtoId]?.nome}
        </Link>
      );
    },
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Limite de crédito',
    attribute: 'limiteCredito',
    format: FormatValueEnum.BRL,
    sortable: true,
    className: 'text-end',
  },
  {
    label: 'Ações',
    format: (_, clientePreAprovadoProduto) => {
      return (
        <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.REMOCAO_CLIENTE_PRE_APROVADO_PRODUTO}>
          <div className="d-flex justify-content-center">
            <div className="mx-1">
              <IconButton
                Icon={BsTrash}
                type={ClassTypesEnum.DANGER}
                onClick={() => {
                  showDialog({
                    component: (
                      <ConfirmationDialog
                        onConfirmation={() => onRemoveClientePreAprovadoProdutoHandler(clientePreAprovadoProduto._id)}
                        message="Tem certeza que deseja remover o cliente pré-aprovado?"
                        confirmationLabel="Remover"
                        title="Remover cliente pré-aprovado"
                        onHide={closeDialog}
                      />
                    ),
                  });
                }}
              />
            </div>
          </div>
        </RbacElement>
      );
    },
  },
];

const ClientesPreAprovadosProduto: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;
  const { query, maxItemsQuery } = useQuerystring();
  const { closeDialog, showDialog } = useDialog();
  const { showToast } = useToasts();

  const filters = useMemo(() => ({ ...query }), [query]);
  const clientesPreAprovadosProdutos = useSelector((state) => selectClientePreAprovadoProdutoByFilters(state, filters));
  const total = useSelector((state) => selectTotalClientePreAprovadoProdutoByFilters(state, filters));
  const loadingState = useSelector((state) => selectClientePreAprovadoProdutoLoadingStateByFilters(state, filters));
  const produtos = useSelector((state) => selectObjectTodosProdutos(state));

  const produtosMap = Object.entries(produtos || {}).reduce(
    (acc, [produtoId, produto]) => ({
      ...acc,
      [produtoId]: `${produto.codigo} - ${produto.nome}`,
    }),
    {}
  );

  const loadEntidadesComplementares = useCallback(
    (clientePreAprovadoProduto: any) => {
      const produtos = mapearERemoverElementosNulosERepetidos(clientePreAprovadoProduto, 'produtoId');

      dispatch(loadProdutos({ query: { _id: { in: produtos }, ...maxItemsQuery } })).catch((error: Error) => error);
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(
    () =>
      dispatch(loadClientesPreAprovadosProduto({ query }))
        .then(({ payload: { data } }: ApiMultiElementResponse) => loadEntidadesComplementares(data))
        .catch((error: Error) => error),
    [dispatch, loadEntidadesComplementares, query]
  );

  const onNovoClientePreAprovadoProdutoHandler = () => {
    showDialog({
      component: <CadastrarClientePreAprovadoProdutoDialog closeDialog={closeDialog} reload={loadItems} />,
    });
  };

  const onRemoveClientePreAprovadoProdutoHandler = (clientePreAprovadoProdutoId: string) =>
    dispatch(deleteClientePreAprovadoProduto({ params: { clientePreAprovadoProdutoId } }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Cliente pré-aprovado removido com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return loadItems();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CLIENTE_PRE_APROVADO_PRODUTO}>
      <div className="d-flex flex-column h-100">
        <Title> Clientes pré-aprovados por produto </Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters({ produtosMap })} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_CLIENTE_PRE_APROVADO_PRODUTO}>
                  <div className="d-flex align-items-center mb-2">
                    <SmartTableButton onClick={onNovoClientePreAprovadoProdutoHandler}>
                      Novo cliente pré-aprovado
                    </SmartTableButton>
                  </div>
                </RbacElement>

                <SmartTable
                  emptyMessage="Nenhum cliente pré-aprovado encontrado"
                  errorMessage="Erro na listagem de clientes pré-aprovados "
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({
                    closeDialog,
                    onRemoveClientePreAprovadoProdutoHandler,
                    produtos,
                    showDialog,
                  })}
                  items={clientesPreAprovadosProdutos}
                  loadingState={loadingState}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default ClientesPreAprovadosProduto;
