import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { BsPencilSquare, BsTrash } from 'react-icons/bs';
import IconButton from '../../components/button/icon-button';
import ConfirmationDialog from '../../components/dialog/confirmation-dialog';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import ExportCSVButton from '../../components/smart-table/export-csv-button';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import { ClassTypesEnum, FormatValueEnum } from '../../helpers';
import { DialogProps } from '../../hooks/dialog/dialog-context';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import AtualizarItemDoacaoDialog from './atualizar-item-doacao-dialog';
import CadastrarItemDoacaoDialog from './cadastrar-item-doacao-dialog';
import {
  deleteItemDoacao,
  loadItensDoacao,
  selectItensDoacaoByFilters,
  selectItensDoacaoLoadingStateByFilters,
  selectTotalItensDoacaoByFilters,
  updateItemDoacao,
} from './itens-doacao.redux';

const smartFilters = (): SmartTableFilterProps[] => [
  {
    label: 'Descrição',
    attribute: 'descricao',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
    minLength: 4,
  },
  {
    label: 'Valor unitário (R$)',
    attribute: 'valor',
    controlType: SmartTableFilterControlTypesEnum.CURRENCY_RANGE,
  },
  {
    label: 'Valor mínimo (R$)',
    attribute: 'limiteMinimoFaixa',
    controlType: SmartTableFilterControlTypesEnum.CURRENCY_RANGE,
  },
  {
    label: 'Valor máximo (R$)',
    attribute: 'limiteMaximoFaixa',
    controlType: SmartTableFilterControlTypesEnum.CURRENCY_RANGE,
  },
];
const csvHeaders = [
  {
    label: 'Descrição',
    key: 'descricao',
  },
  { label: 'Valor Máximo', key: 'limiteMaximoFaixa' },
  { label: 'Valor Mínimo', key: 'limiteMinimoFaixa' },
  { label: 'Valor unitário', key: 'valor' },
];
const smartColumns = ({
  showDialog,
  closeDialog,
  onRemoveItemDoacaoHandler,
  onUpdateItemDoacaoHandler,
}: {
  showDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
  onRemoveItemDoacaoHandler: (itemDoacaoId: string) => void;
  onUpdateItemDoacaoHandler: (itemDoacaoId: string, data: any) => void;
}): SmartTableColumnProps[] => [
  { label: 'Descrição', attribute: 'descricao', sortable: true, className: 'text-center' },
  { label: 'Valor unitário', attribute: 'valor', format: FormatValueEnum.BRL, sortable: true, className: 'text-end' },
  {
    label: 'Valor mínimo',
    attribute: 'limiteMinimoFaixa',
    format: FormatValueEnum.BRL,
    sortable: true,
    className: 'text-end',
  },
  {
    label: 'Valor máximo',
    attribute: 'limiteMaximoFaixa',
    format: FormatValueEnum.BRL,
    sortable: true,
    className: 'text-end',
  },
  {
    label: 'Ações',
    format: (_, itemDoacao) => {
      return (
        <div className="d-flex justify-content-center">
          <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_ITEM_DOACAO}>
            <div className="mx-1">
              <IconButton
                Icon={BsPencilSquare}
                type={ClassTypesEnum.PRIMARY}
                onClick={() => {
                  showDialog({
                    component: (
                      <AtualizarItemDoacaoDialog
                        closeDialog={closeDialog}
                        itemDoacao={itemDoacao}
                        onUpdateHandler={onUpdateItemDoacaoHandler}
                      />
                    ),
                  });
                }}
              />
            </div>
          </RbacElement>

          <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.REMOCAO_ITEM_DOACAO}>
            <div className="mx-1">
              <IconButton
                Icon={BsTrash}
                type={ClassTypesEnum.DANGER}
                onClick={() => {
                  showDialog({
                    component: (
                      <ConfirmationDialog
                        onConfirmation={() => onRemoveItemDoacaoHandler(itemDoacao._id)}
                        message="Tem certeza que deseja remover o item de doação?"
                        confirmationLabel="Remover item de doação"
                        title="Remover item de doação"
                        onHide={closeDialog}
                      />
                    ),
                  });
                }}
              />
            </div>
          </RbacElement>
        </div>
      );
    },
  },
];

const ItensDoacaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query } = useQuerystring();
  const { closeDialog, showDialog } = useDialog();
  const { showToast } = useToasts();

  if (!query.sort) {
    query.sort = '-valorUnitario';
  }

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalItensDoacaoByFilters(state, filters));
  const itensDoacao = useSelector((state) => selectItensDoacaoByFilters(state, filters));
  const loadingState = useSelector((state) => selectItensDoacaoLoadingStateByFilters(state, filters));

  const loadItems = useCallback(
    () => dispatch(loadItensDoacao({ query })).catch((error: Error) => error),
    [dispatch, query]
  );

  const onRemoveItemDoacaoHandler = (itemDoacaoId: string) =>
    dispatch(deleteItemDoacao({ params: { itemDoacaoId } }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Item de doação removido com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return loadItems();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });

  const onUpdateItemDoacaoHandler = (itemDoacaoId: string, data: any) => {
    if (!data) {
      return;
    }

    dispatch(updateItemDoacao({ params: { itemDoacaoId }, data }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Item de doação atualizado com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return loadItems();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  const onNovoItemDoacaoClickHandler = () => {
    showDialog({
      component: <CadastrarItemDoacaoDialog closeDialog={closeDialog} reload={loadItems} />,
    });
  };

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_ITEM_DOACAO}>
      <div className="d-flex flex-column h-100">
        <Title>Itens de doação</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters()} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <div className="d-flex align-items-center mb-2">
                  <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_ITEM_DOACAO}>
                    <SmartTableButton onClick={onNovoItemDoacaoClickHandler}>Novo item de doação</SmartTableButton>
                    <ExportCSVButton data={itensDoacao} filename="itens-doacao" headers={csvHeaders} />
                  </RbacElement>
                </div>

                <SmartTable
                  emptyMessage="Nenhum item de doação encontrado"
                  errorMessage="Erro na listagem de itens doação"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({
                    closeDialog,
                    showDialog,
                    onRemoveItemDoacaoHandler,
                    onUpdateItemDoacaoHandler,
                  })}
                  items={itensDoacao}
                  loadingState={loadingState}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default ItensDoacaoPage;
