import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';

import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import {
  ApiMultiElementResponse,
  FormatValueEnum,
  mapearERemoverElementosNulosERepetidos,
  naturezaTransacaoLabelMap,
} from '../../helpers';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadContasCartao, selectObjectTodasContasCartao } from '../contas-cartao/contas-cartao.redux';
import CadastrarLancamentoContabilDialog from './cadastrar-lancamento-contabil-dialog';
import {
  loadLancamentosContabeis,
  selectLancamentoContabilLoadingStateByFilters,
  selectLancamentosContabeisByFilters,
  selectTotalLancamentosContabeisByFilters,
} from './lancamento-contabil.redux';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import ExportCSVButton from '../../components/smart-table/export-csv-button';

const smartFilters = (): SmartTableFilterProps[] => [
  {
    label: 'Conta cartão',
    attribute: 'contaCartao',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Data de lançamento',
    attribute: 'dataLancamento',
    controlType: SmartTableFilterControlTypesEnum.DATE_INPUT,
  },
  {
    label: 'Natureza',
    attribute: 'natureza',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: naturezaTransacaoLabelMap,
  },
  {
    label: 'Valor (R$)',
    attribute: 'valor',
    controlType: SmartTableFilterControlTypesEnum.CURRENCY_INPUT,
  },
];

const smartColumns = ({ contasCartao }: { contasCartao: any }): SmartTableColumnProps[] => [
  {
    label: 'Conta cartão',
    attribute: 'contaCartao',
    format(contaCartao) {
      if (!contaCartao) {
        return '-';
      }

      return (
        <Link onClick={(e) => e.stopPropagation()} to={`/contas-cartao/${contaCartao}`}>
          {contasCartao[contaCartao]?.numero}
        </Link>
      );
    },
    className: 'text-center',
    sortable: true,
  },

  {
    label: 'Descrição',
    attribute: 'descricao',
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Data de lançamento',
    attribute: 'dataLancamento',
    format: FormatValueEnum.DATA,
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Conta contábil',
    attribute: 'contaContabil',
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Conta de contra partida',
    attribute: 'contaContraPartida',
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Natureza',
    attribute: 'natureza',
    format: FormatValueEnum.ENUM,
    map: naturezaTransacaoLabelMap,
    sortable: true,
    className: 'text-center',
  },
  {
    label: 'Valor',
    attribute: 'valor',
    format: FormatValueEnum.BRL,
    sortable: true,
    className: 'text-end',
  },
];

const LancamentosContabeisPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query, maxItemsQuery } = useQuerystring();
  const navigate = useNavigate();
  const { showDialog, closeDialog } = useDialog();

  if (!query.sort) {
    query.sort = '-dataLancamento';
  }

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalLancamentosContabeisByFilters(state, filters));
  const lancamentos = useSelector((state) => selectLancamentosContabeisByFilters(state, filters));
  const loadingState = useSelector((state) => selectLancamentoContabilLoadingStateByFilters(state, filters));
  const contasCartao = useSelector((state) => selectObjectTodasContasCartao(state));

  const loadEntidadesComplementares = useCallback(
    (lancamentosContabeis: any[]) => {
      const contasCartao = mapearERemoverElementosNulosERepetidos(lancamentosContabeis, 'contaCartao');
      dispatch(loadContasCartao({ query: { _id: { in: contasCartao }, ...maxItemsQuery } })).catch(
        (error: Error) => error
      );
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(
    () =>
      dispatch(loadLancamentosContabeis({ query }))
        .then(({ payload: { data } }: ApiMultiElementResponse) => loadEntidadesComplementares(data))
        .catch((error: Error) => error),
    [dispatch, loadEntidadesComplementares, query]
  );

  const lancamentoContabilCSVData = lancamentos?.map(
    ({ _id, ...dadosLancamentosContabeis }: any) => dadosLancamentosContabeis
  );

  const onLancamentoContabilClickHandler = () => {
    showDialog({ component: <CadastrarLancamentoContabilDialog closeDialog={closeDialog} reload={loadItems} /> });
  };

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_LANCAMENTO_CONTABIL}>
      <div className="d-flex flex-column h-100">
        <Title>Lançamentos contábeis</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters()} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <div className="d-flex align-items-center mb-2">
                  <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_LANCAMENTO_CONTABIL}>
                    <SmartTableButton onClick={onLancamentoContabilClickHandler}>Novo lançamento</SmartTableButton>
                  </RbacElement>
                  <ExportCSVButton data={lancamentoContabilCSVData} filename="lancamentos-contabeis" />
                </div>

                <SmartTable
                  emptyMessage="Nenhum lançamento contábil encontrado"
                  errorMessage="Erro na listagem de lançamentos contábeis"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({ contasCartao })}
                  items={lancamentos}
                  loadingState={loadingState}
                  size={total}
                  onRowClick={(doc) => navigate(`/lancamentos-contabeis/${doc._id}`)}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default LancamentosContabeisPage;
