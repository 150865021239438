import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useMemo } from 'react';
import BSCard from 'react-bootstrap/Card';
import { BsPencilSquare } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import IconButton from '../../components/button/icon-button';
import RbacElement from '../../components/role-based-access-control/role-based-access-control-element';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableButton from '../../components/smart-table/smart-table-button';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import {
  ApiMultiElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  SizesEnum,
  mapearERemoverElementosNulosERepetidos,
  tipoTarifaLabelMap,
} from '../../helpers';
import { DialogProps } from '../../hooks/dialog/dialog-context';
import { useDialog } from '../../hooks/dialog/use-dialog';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadProdutos, selectObjectTodosProdutos } from '../produtos/produtos.redux';
import { loadTiposTransacao, selectObjectTodosTiposTransacao } from '../tipos-transacao/tipos-transacao.redux';
import AtualizarTarifaDialog from './atualizar-tarifa-dialog';
import CadastrarTarifaDialog from './cadastrar-tarifa-dialog';
import {
  loadTarifas,
  selectTarifasByFilters,
  selectTarifasLoadingStateByFilters,
  selectTotalTarifasByFilters,
  updateTarifa,
} from './tarifas.redux';

const smartFilters = (): SmartTableFilterProps[] => [
  {
    label: 'Código',
    attribute: 'codigo',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    label: 'Valor (R$)',
    attribute: 'valor',
    controlType: SmartTableFilterControlTypesEnum.CURRENCY_INPUT,
  },
  {
    label: 'Tipo da tarifa',
    attribute: 'tipoTarifa',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: tipoTarifaLabelMap,
  },
];

const smartColumns = ({
  tiposTransacao,
  produtos,
  showDialog,
  closeDialog,
  onUpdateTarifaHandler,
}: {
  tiposTransacao: any;
  produtos: any;
  showDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
  onUpdateTarifaHandler: (tarifaId: string, data: any) => void;
}): SmartTableColumnProps[] => [
  {
    label: 'Código',
    attribute: 'codigo',
    className: 'text-end',
    sortable: true,
  },
  {
    label: 'Descrição',
    attribute: 'descricao',
    className: 'text-center',
    sortable: true,
  },
  {
    label: 'Tipo da tarifa',
    attribute: 'tipoTarifa',
    className: 'text-center',
    format: FormatValueEnum.ENUM,
    map: tipoTarifaLabelMap,
    sortable: true,
  },
  {
    label: 'Valor (R$)',
    attribute: 'valor',
    className: 'text-end',
    format: FormatValueEnum.BRL,
    sortable: true,
  },
  {
    label: 'Produto',
    attribute: 'produto',
    className: 'text-center',
    format(produto) {
      if (!produto) {
        return '-';
      }

      return (
        <Link onClick={(e) => e.stopPropagation()} to={`/produtos/${produto}`}>
          {produtos[produto]?.nome}
        </Link>
      );
    },
  },
  {
    label: 'Tipo de transação',
    attribute: 'tipoTransacao',
    className: 'text-center',
    format(tipoTransacao) {
      if (!tipoTransacao) {
        return '-';
      }

      return (
        <Link onClick={(e) => e.stopPropagation()} to={`/tipos-transacao/${tipoTransacao}`}>
          {tiposTransacao[tipoTransacao]?.codigo} - {tiposTransacao[tipoTransacao]?.descricao}
        </Link>
      );
    },
  },
  {
    label: 'Ações',
    format: (_, tarifa) => {
      return (
        <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.ALTERACAO_TARIFA}>
          <div className="d-flex justify-content-center">
            <div className="mx-1">
              <IconButton
                Icon={BsPencilSquare}
                type={ClassTypesEnum.PRIMARY}
                onClick={() => {
                  showDialog({
                    component: (
                      <AtualizarTarifaDialog
                        closeDialog={closeDialog}
                        tarifa={tarifa}
                        onUpdateHandler={onUpdateTarifaHandler}
                      />
                    ),
                    size: SizesEnum.LARGE,
                  });
                }}
              />
            </div>
          </div>
        </RbacElement>
      );
    },
  },
];

const TarifasPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query, maxItemsQuery } = useQuerystring();
  const navigate = useNavigate();
  const { showToast } = useToasts();

  const { closeDialog, showDialog } = useDialog();

  if (!query.sort) {
    query.sort = '-data';
  }

  const filters = useMemo(() => ({ ...query }), [query]);
  const total = useSelector((state) => selectTotalTarifasByFilters(state, filters));
  const tarifas = useSelector((state) => selectTarifasByFilters(state, filters));
  const loadingState = useSelector((state) => selectTarifasLoadingStateByFilters(state, filters));
  const produtos = useSelector((state) => selectObjectTodosProdutos(state));
  const tiposTransacao = useSelector((state) => selectObjectTodosTiposTransacao(state));

  const loadProdutosETiposTransacao = useCallback(
    (taridas: any[]) => {
      const produtos = mapearERemoverElementosNulosERepetidos(taridas, 'produto');
      const tiposTransacao = mapearERemoverElementosNulosERepetidos(taridas, 'tipoTransacao');

      dispatch(loadProdutos({ query: { _id: { in: produtos }, ...maxItemsQuery } })).catch((error: Error) => error);
      dispatch(loadTiposTransacao({ query: { _id: { in: tiposTransacao }, ...maxItemsQuery } })).catch(
        (error: Error) => error
      );
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(
    () =>
      dispatch(loadTarifas({ query: filters }))
        .then(({ payload: { data } }: ApiMultiElementResponse) => loadProdutosETiposTransacao(data))
        .catch((error: Error) => error),
    [dispatch, filters, loadProdutosETiposTransacao]
  );

  const onNovoTarifaHandler = () => {
    showDialog({
      component: <CadastrarTarifaDialog closeDialog={closeDialog} reload={loadItems} />,
      size: SizesEnum.LARGE,
    });
  };

  const onUpdateTarifaHandler = (tarifaId: string, data: any) => {
    if (!data) {
      return;
    }

    dispatch(updateTarifa({ params: { tarifaId }, data }))
      .then(() => {
        closeDialog();

        showToast({
          message: 'Tarifa atualizada com sucesso',
          type: ClassTypesEnum.SUCCESS,
        });

        return loadItems();
      })
      .catch((error: Error) => {
        showToast({
          message: error.message,
          type: ClassTypesEnum.DANGER,
        });
      });
  };

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TARIFA}>
      <div className="d-flex flex-column h-100">
        <Title>Tarifas</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters()} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <RbacElement acoesPermissao={AcaoPermissaoPapelUsuarioEnum.CRIACAO_TARIFA}>
                  <div className="d-flex align-items-center mb-2">
                    <SmartTableButton onClick={onNovoTarifaHandler}>Nova tarifa</SmartTableButton>
                  </div>
                </RbacElement>
                <SmartTable
                  emptyMessage="Nenhuma tarifa encontrada"
                  errorMessage="Erro na listagem de tarifas"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({ closeDialog, showDialog, produtos, tiposTransacao, onUpdateTarifaHandler })}
                  items={tarifas}
                  loadingState={loadingState}
                  size={total}
                  onRowClick={(doc) => navigate(`/tarifas/${doc._id}`)}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default TarifasPage;
