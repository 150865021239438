import BSFloatingLabel from 'react-bootstrap/FloatingLabel';
import BSButton from 'react-bootstrap/Button';
import BSInputGroup from 'react-bootstrap/InputGroup';
import BSForm from 'react-bootstrap/Form';
import { useSearchParams } from 'react-router-dom';
import { SmartTableFilterComponentProps } from './table-filter.types';
import { BsX } from 'react-icons/bs';
import React, { useEffect, useState } from 'react';
import { getMinLengthErrorMessage, validateMinLength } from './smart-table-filters';
import { formatCurrency } from '../../../helpers';

enum RangeAttibuteTypeEnum {
  MAX = 'max',
  MIN = 'min',
}

const { MIN, MAX } = RangeAttibuteTypeEnum;

const RangeCurrencyFilter: React.FC<SmartTableFilterComponentProps> = ({
  filter,
  removeAttributeFromSearchParams,
  upsertAttributeInSearchParams,
}) => {
  const [searchParams] = useSearchParams();
  const [minFormControlValue, setMinFormControlValue] = useState('');
  const [maxFormControlValue, setMaxFormControlValue] = useState('');

  const [minInvalid, setMinInvalid] = useState(false);
  const [minError, setMinError] = useState('');

  const [maxInvalid, setMaxInvalid] = useState(false);
  const [maxError, setMaxError] = useState('');

  const { attribute, label, className, minLength } = filter;

  const minAttribute = `${attribute}[gteRange]`;
  const maxAttribute = `${attribute}[lteRange]`;

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>, rangeAttribute: RangeAttibuteTypeEnum) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      const { value } = event.target as HTMLInputElement;

      if (rangeAttribute === MIN) {
        if (!value) {
          return removeAttributeFromSearchParams(minAttribute);
        }

        if (!minInvalid) {
          return upsertAttributeInSearchParams(minAttribute, value);
        }

        return;
      }

      if (!value) {
        return removeAttributeFromSearchParams(maxAttribute);
      }

      if (!maxInvalid) {
        return upsertAttributeInSearchParams(maxAttribute, value);
      }
    }
  };

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rangeAttribute: RangeAttibuteTypeEnum
  ) => {
    const { value } = event.target;
    event.preventDefault();

    if (rangeAttribute === MIN) {
      setMinFormControlValue(formatCurrency(value));

      if (minLength && validateMinLength(minLength, value)) {
        setMinInvalid(true);
        setMinError(getMinLengthErrorMessage(minLength));
        return;
      }

      setMinInvalid(false);
      setMinError('');
      return;
    }

    setMaxFormControlValue(formatCurrency(value));

    if (minLength && validateMinLength(minLength, value)) {
      setMaxInvalid(true);
      setMaxError(getMinLengthErrorMessage(minLength));
      return;
    }

    setMaxInvalid(false);
    setMaxError('');
  };

  const clearFilterHandler = (rangeAttribute: RangeAttibuteTypeEnum) => {
    if (rangeAttribute === MIN) {
      setMinInvalid(false);
      setMinFormControlValue('');
      setMinError('');

      return removeAttributeFromSearchParams(minAttribute);
    }

    setMaxInvalid(false);
    setMaxFormControlValue('');
    setMaxError('');

    return removeAttributeFromSearchParams(maxAttribute);
  };

  useEffect(() => {
    const minValue = searchParams.get(minAttribute);
    setMinFormControlValue(minValue ?? '');

    const maxValue = searchParams.get(maxAttribute);
    setMaxFormControlValue(maxValue ?? '');
  }, [maxAttribute, minAttribute, searchParams]);

  return (
    <BSForm data-testid="range-input-filter" noValidate className={className ?? 'mb-3'}>
      <BSForm.Group className="mb-3">
        <BSForm.Label>{label}</BSForm.Label>
        <BSInputGroup className="d-flex flex-nowrap mb-2">
          <BSFloatingLabel label="De" className="w-100">
            <BSForm.Control
              type="text"
              placeholder="De"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e, MIN)}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyDownHandler(e, MIN)}
              value={minFormControlValue}
              isInvalid={minInvalid}
            />
            {minLength && <BSForm.Control.Feedback type="invalid">{minError}</BSForm.Control.Feedback>}
          </BSFloatingLabel>
          {searchParams.get(minAttribute) && (
            <BSButton variant="outline-primary" onClick={() => clearFilterHandler(MIN)}>
              <BsX size={20} />
            </BSButton>
          )}
        </BSInputGroup>

        <BSInputGroup className="d-flex flex-nowrap">
          <BSFloatingLabel label="Até" className="w-100">
            <BSForm.Control
              type="text"
              placeholder="Até"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e, MAX)}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyDownHandler(e, MAX)}
              value={maxFormControlValue}
              isInvalid={maxInvalid}
            />
            {minLength && <BSForm.Control.Feedback type="invalid">{maxError}</BSForm.Control.Feedback>}
          </BSFloatingLabel>
          {searchParams.get(maxAttribute) && (
            <BSButton variant="outline-primary" onClick={() => clearFilterHandler(MAX)}>
              <BsX size={20} />
            </BSButton>
          )}
        </BSInputGroup>
      </BSForm.Group>
    </BSForm>
  );
};

export default RangeCurrencyFilter;
